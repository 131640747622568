//课程中心
<template>
  <div class="course_center">
    <div class="content" v-if="!showLoading">
      <div
        :class="['item_course', (index + 1) % 4 != 0 ? 'hasMargin' : 'noMargin']"
        @mouseover="item.isShow = true"
        @mouseout="item.isShow = false"
        v-for="(item, index) in courseData"
        :key="index"
        @click.stop="goChooseChapter(item)"
      >
        <div class="top_img">
          <img class="item_img" :src="item.curriculumCover" />
        </div>

        <span class="item_name">{{ item.curriculumName }}</span>

        <!--  蒙版层 -->
        <div class="mengban" v-show="item.isShow"></div>
      </div>
    </div>

    <!-- 加载框 -->
    <Loading v-else></Loading>
  </div>
</template>
<script>
export default {
  name: "CourseCenter",
  data() {
    return {
      //是否显示Loading
      showLoading: true,
      //是否登录,赛事中心进入页面是不需要登录权限
      isLogin: false,
      courseData: []
    };
  },

  created() {
    //初始化登录状态
    this.initLoginState();
  },

  mounted() {
    //请求数据
    this.httpData();
  },

  methods: {
    //初始化登录权限
    initLoginState() {
      if (this.hasLogin()) {
        this.isLogin = true;
      } else {
        this.isLogin = false;
      }
    },

    //选择章节界面
    goChooseChapter(item) {
      if (this.isLogin) {
        //非赛事中心须访问需要权限
        let role = this.getUserRole();
        if (role == 0 || role == -1) {
          //学生角色不让进入
          this.showWarnMsg("暂无访问权限!");
        } else {
          let obj = new Object();
          obj.curriculumName = item.curriculumName;
          obj.curriculumCenterId = item.curriculumCenterId;
          this.$router.push({
            path: "/LoginContain/ChooseChapter",
            query: obj
          });
        }
      } else {
        //赛事中心访问页面不需要权限,并且跳转的页面不同
        const obj = new Object();
        obj.curriculumCenterId = item.curriculumCenterId;
        // obj.curriculumChapterId = item.curriculumChapterId;
        // obj.chapterType = item.chapterType;
        // obj.chapterName = item.chapterName;
        this.$router.push({
          path: "/LoginContain/ChooseLesson",
          query: obj
        });
      }
    },

    //获取列表数据
    async httpData() {
      let param = this.getHttpParams();
      if (this.isLogin) {
        param.type = 1;
      } else {
      }
      let res = await this.$http.fetchPost(this.$api.LESSON_CENTER, param);
      if (res.data.state == 200) {
        //隐藏Loading框
        this.showLoading = false;

        //表示请求成功
        this.courseData = res.data.data;

        //循环给每个条目加入一个响应式的控制遮罩的值
        this.courseData.forEach(item => {
          this.$set(item, "isShow", false);
        });
      } else {
        //直接将服务器返回的错误内容弹出提示
        this.showErrorMsg(this.getMesage(res.data.state));
      }
    }
  },
  computed: {},
  components: {}
};
</script>
<style lang="scss" scoped>
.course_center {
  background: $common_gray;
  height: calc(100vh - 87px);
  overflow-y: auto;

  .content {
    padding-top: 20px;
    padding-bottom: 20px;
    width: $common_width;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    .item_course {
      position: relative;
      margin-bottom: 20px;
      height: 350px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      // width: 360px;
      width: 255px;
      background: white;
      border-radius: 14px;
      color: $common_bg;
      font-weight: bold;
      cursor: pointer;

      .top_img {
        margin-top: 20px;
        border-radius: 10px;
        flex: 1;
        width: 86%;
        display: flex;
        align-items: center;
        justify-content: center;

        .item_img {
          width: 100%;
        }

        .width_sp {
          width: 79%;
        }
      }

      //黄色背景
      .bg_color {
        background: #fff3c4;
      }

      .item_name {
        margin-top: 20px;
        height: 60px;
      }

      .mengban {
        width: 100%;
        height: 100%;
        border-radius: 14px;
        position: absolute;
        background: rgba($color: #000000, $alpha: 0.2);
      }
    }

    // .item_course:hover {
    //   background: rgba($color: #000000, $alpha: 0.2);
    // }

    .hasMargin {
      margin-right: 60px;
    }

    .noMargin {
      margin-right: 0px;
    }
  }
}
//去除滚动条
.course_center::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
</style>
